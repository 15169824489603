import OpacityParallax from "@components/parallax-opacity"
import ParallaxSticky from "@components/parallax-sticky"
import Scrollshow from "@components/scrollshow"
import {
  Block,
  CenterCite,
  CenterText,
  Figure,
  Intro,
  IntroImage,
  NavLink,
  NavLinks,
  ParallaxBlock,
  StoryHeading,
  StoryHeadingBg,
  StoryMeta,
  StoryTitle,
  TextBlock,
  VideoQuote,
} from "@components/story-components"
import StoryPicker from "@components/storypicker"
import Video from "@components/video"
import YoutubeVideo from "@components/YoutubeVideo"
import { graphql } from "gatsby"
import React from "react"
import { FluidImg } from "src/types"

import khairulMp4 from "../../assets/khairul.mp4"
import khairulWebM from "../../assets/khairul.webm"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftIcon from "../../images/chevron-left.inline.svg"
import RightIcon from "../../images/chevron-right.inline.svg"

type Props = {
  data: {
    khairul: FluidImg
    school: FluidImg
    pray: FluidImg
    preach: FluidImg
    boardingschool: FluidImg
    boardingschool2: FluidImg
  }
}

const IndexPage = ({ data }: Props) => {
  return (
    <Layout>
      <SEO title="Khairul's story" />
      <Block>
        <Video
          sources={[
            { src: khairulWebM, type: "video/webm" },
            { src: khairulMp4, type: "video/mp4" },
          ]}
        />
        <VideoQuote>
          <CenterCite maxWidth="63rem" color="wheat">
            Khairul's Story
            <StoryMeta>Indonesia</StoryMeta>
          </CenterCite>
        </VideoQuote>
        <StoryHeading>
          <StoryHeadingBg>
            <NavLinks>
              <NavLink to="/story/ahmad">
                <LeftIcon height="1em" strokeWidth="2px" /> Previous
              </NavLink>
              <NavLink to="/story/amir">
                Next story <RightIcon height="1em" strokeWidth="2px" />
              </NavLink>
            </NavLinks>
            <StoryTitle maxWidth="100ch">
              ‘I met the leader of the Jemaah Islamiyah, Abdullah Sunkar, when I
              was a teenager’
            </StoryTitle>
          </StoryHeadingBg>
        </StoryHeading>
      </Block>
      <Intro>
        <IntroImage fluid={data.khairul.childImageSharp.fluid} />
        <CenterText fontSize={["xl", "2xl"]}>
          After 12 years in Malaysia learning about Islam and being subjected to
          a lot of propaganda, I came back to Indonesia and became involved in
          terrorist activities. I was eventually arrested and convicted for my
          involvement in a CIMB bank robbery in 2010, an attack on Hamparan
          Perak sub-precinct police station that killed three policemen, and
          also for military training in Jantho, in Aceh Indonesia.
        </CenterText>
      </Intro>
      <OpacityParallax
        bg="cyprus"
        threshold={0.7}
        backgroundProps={{
          fluid: data.school.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat">
            <CenterText fontSize={["xl", "2xl"]}>
              In prison, I realized my child was no longer going to school
              because he was being bullied. The other children would say things
              like, “Your father is a terrorist. He commits treason.” This was
              also happening to the children of other terrorists. That really
              affected me.
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <TextBlock color="cyprus">
        <CenterCite>
          ‘The children are victims in this. They pay the price for their
          parent’s choices. ’
        </CenterCite>
      </TextBlock>
      <ParallaxBlock
        scaleFactor={1.6}
        backgroundProps={{
          fluid: data.pray.childImageSharp.fluid,
          loading: "eager",
        }}
      />
      <Scrollshow
        fluidImages={[
          data.boardingschool.childImageSharp.fluid,
          data.boardingschool2.childImageSharp.fluid,
        ]}
      />
      <TextBlock bg="cyprus" color="wheat">
        <CenterText fontSize={["xl", "2xl"]}>
          When I was freed from prison, I established an Islamic boarding school
          which acts as a place for trauma healing for the children of
          terrorist. I took in ten students whose fathers had been killed or
          were still imprisoned. The community wasn’t happy at first - they
          vandalized the school sign on three different occasions. Now we have
          48 pupils, and those who initially refused to accept our school are
          now sending their own children to study here.
        </CenterText>
      </TextBlock>
      <OpacityParallax
        bg="wine"
        threshold={0.5}
        backgroundProps={{
          fluid: data.preach.childImageSharp.fluid,
          objectPosition: "50% 0%",
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat">
            <CenterText fontSize={["xl", "2xl"]}>
              When I became a terrorist, that decision was full of action. Now I
              am repentant and back on the right path, but I must be able to
              prove it also with action. Rehabilitating these children is my way
              of doing that.
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <TextBlock p="" py="5xl" bg="cyprus">
        <Figure>
          <YoutubeVideo videoId="s7_xku7OWUc" />
        </Figure>
      </TextBlock>
      <StoryPicker />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    khairul: file(relativePath: { eq: "khairul.jpg" }) {
      ...BlockImage
    }
    school: file(relativePath: { eq: "khairul/school.jpg" }) {
      ...BlockImage
    }
    pray: file(relativePath: { eq: "khairul/pray.jpg" }) {
      ...BlockImage
    }
    preach: file(relativePath: { eq: "khairul/preach.jpg" }) {
      ...BlockImage
    }
    boardingschool: file(relativePath: { eq: "khairul/boardingschool.jpg" }) {
      ...BlockImage
    }
    boardingschool2: file(relativePath: { eq: "khairul/boardingschool2.jpg" }) {
      ...BlockImage
    }
  }
`

export default IndexPage
